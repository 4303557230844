export default {
	data: () => ({
		langRo: {
			title: 'Coșul tău',
			date: {
				title: 'Completeaza datele',
				name: 'Nume',
        		surname: 'Prenume',
				phone: 'Telefon',
				city: 'Oraș',
				district: 'Județ',
				houseNumber: 'Numărul',
				block: 'Bloc',
				email: 'E-mail',
				addressInvoice: 'Address invoice',
				country: 'Țara',
				street: 'Strada',
				streetRu: 'Strada',
				flat: 'Apartament',
				person: 'Persoană fizică',
				company: 'Persoană juridică',
				companyName: 'Numele companiei',
				fiscalCode: 'CIF firmei',
				tradeNumber: 'RC Firmei',
				companyAddress: 'Adresa firmei'
			},
			delivery: {
				title: 'Livrare',
				courier: 'Livrare',
				shop: 'Ridicare din magazin',
				courierDesc: 'Livrarea în departament sau prin curier',
				courierDescRo: 'Daca valoarea comenzii depaseste 500 de lei, livrarea este GRATUITA',
				shopDesc: 'Pentru cei care doresc sa ne viziteze',
				address: 'Adresa deplină',
        info: "Livrare gratuită: Chișinău - pentru comenzi mai mari de 800 lei; regiuni - pentru comenzi mai mari de 1000 lei. În caz contrar, plata transportului va fi suportată de client și calculată de Nova Poshta (costul difera în funcție de localitate și greutatea coletului).",
        novaPosta: "Livrarea se realizează de către compania Nova Poshta",
        selectType: {
          courier: "Curier",
          inOffice: "Din oficiu Nova Poshta"
        }
			},
      street: {
        houseNumber: "Nr.",
        flat: "ap."
      },
			payment: {
				title: 'Modalitati de plata',
				сash: 'Cash',
				card: 'Card bancar'
			},
			confirmare: {
				title: 'Confirmare',
				comments: 'Comentarii',
				promo: 'Cod promoțional',
				delivery: 'Livrare',
				total: 'Total',
				finish: 'Finalizeaza comanda',
				conditions: 'Sunt de acord cu termenii',
				policy: 'Politica de confidețialitate'
			},
      useCashback: "Utilizați bonusuri"
		}
	})
}








