export default {
	data: () => ({
		langEng: {
			title: 'Your basket',
			date: {
				name: 'Name',
        surname: 'Surname',
				title: 'Enter the data',
				phone: 'Phone',
				city: 'City',
				district: 'District',
				houseNumber: 'House number',
				email: 'E-mail',
				addressInvoice: 'Address invoice',
				country: 'Country',
				street: 'Street',
				block: 'Block',
				flat: 'Flat',
				person: 'Person',
				company: 'Company',
				companyName: 'Company Name',
				fiscalCode: 'Fiscal identification code',
				tradeNumber: 'Trade register number',
				companyAddress: 'Company address'
			},
			delivery: {
				title: 'Delivery',
				courier: 'Delivery',
				shop: 'From the store',
				courierDesc: 'Delivery in the department or by courier',
				courierDescRo: 'If the order value exceeds 500 lei, the delivery is free',
				shopDesc: 'For those who wish to visit us',
				address: 'Address',
        info: " Free delivery: Chisinau - for orders over 800 lei; regions - for orders over 1000 lei.Otherwise, the transport payment will be borne by the customer and calculated by Nova Poshta (the cost differs depending on the locality and the weight of the package).",
        novaPosta: "Delivery is made by the company Nova Poshta",
        selectType: {
          courier: "Courier",
          inOffice: "In office Nova Poshta"
        }
			},
      street: {
        houseNumber: "Nr h.",
        flat: "flat"
      },
			payment: {
				title: 'Ways of payment',
				сash: 'Cash',
				card: 'Bank card'
			},
			confirmare: {
				title: 'Confirmation',
				comments: 'Comments',
				promo: 'Promo Code',
				delivery: 'Delivery',
				total: 'Total',
				finish: 'Finish order',
				conditions: 'I agree with the terms',
				policy: 'Privacy policy'
			},
      useCashback: "Use bonuses"
		}
	})
}


