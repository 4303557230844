export default {
  data: () => ({
    langEng: {
      title: 'Tu carrito',
      date: {
        name: 'Nombre',
        surname: 'Apellidos',
        title: 'Introduzca los datos',
        phone: 'Teléfono',
        city: 'Ciudad',
        district: 'Distrito',
        houseNumber: 'Número',
        email: 'E-mail',
        addressInvoice: 'Dirección de facturación',
        country: 'País',
        street: 'Calle',
        block: 'Edificio',
        flat: 'Piso',
        person: 'Personal',
        company: 'Empresa',
        companyName: 'Nombre de Empresa',
        fiscalCode: 'NIF/CIF',
        tradeNumber: 'Número registro',
        companyAddress: 'Dirección de la empresa',
      },
      delivery: {
        title: 'Entrega',
        courier: 'Entrega',
        shop: 'Recoger en tienda',
        courierDesc: 'Entrega por mensajería',
        courierDescRo: 'Si el pedido es superior a 500 lei, la entrega es gratuita',
        shopDesc: 'Para quienes quieran visitarnos',
        address: 'Dirección',
        info: "Entrega gratuita: Chisinau – para pedidos superiores a 800 lei; Regiones – para pedidos superiores a 1000 lei. En caso contrario, el coste del transporte correrá a cargo del cliente y lo calculará Nova Poshta (el coste varía según la localidad y el peso del paquete).",
        novaPosta: "La entrega la realiza Nova Poshta",
        selectType: {
          courier: "Mensajería",
          inOffice: "En la oficina Nova Poshta"
        }
      },
      street: {
        houseNumber: "N.º",
        flat: "piso"
      },
      payment: {
        title: 'Métodos de pago',
        cash: 'Efectivo',
        card: 'Tarjeta'
      },
      confirmare: {
        title: 'Confirmación',
        comments: 'Comentarios',
        promo: 'Código promocional',
        delivery: 'Entrega',
        total: 'Total',
        finish: 'Finalizar pedido',
        conditions: 'Acepto los términos',
        policy: 'Política de privacidad'
      },
      useCashback: "Usar bonus"
    }
  })
}
