export default {
	data: () => ({
		langRu: {
			title: 'Твоя корзина',
			date: {
				title: 'Заполните данные',
				name: 'Имя',
				surname: 'Фамилия',
				phone: 'Телефон',
				city: 'Город',
				district: 'Округ',
				houseNumber: 'Номер дома',
				email: 'Электронная почта',
				addressInvoice: 'Address invoice',
				country: 'Страна',
				street: 'Улица',
				block: 'Блок',
				flat: 'Квартира',
				person: 'Физическое лицо',
				company: 'Юридическое лицо',
				companyName: 'Название компании',
				fiscalCode: 'Фискальный идентификационный код',
				tradeNumber: 'Номер в торговом реестре компании',
				companyAddress: 'Адрес компании'
			},
			delivery: {
				title: 'Доставка',
				courier: 'Доставка',
				shop: 'Из магазина',
				courierDesc: 'Доставка в отделении или курьером',
				courierDescRo: 'Если стоимость заказа превышает 500 леев, доставка бесплатная',
				shopDesc: 'Для тех кто желает нас посетить',
				address: 'Адрес',
        info: "Бесплатная доставка: Кишинев - для заказов от 800 лей; регионы - при заказе от 1000 лей. В противном случае транспортную оплату берет на себя заказчик и рассчитывает «Nova Poshta » (стоимость различается в зависимости от населенного пункта и веса посылки)",
        novaPosta: "Доставка осуществляется компанией Nova Poshta",
        selectType: {
          courier: "Курьер",
          inOffice: "В отделении Nova Poshta"
        }
			},
      street: {
        houseNumber: "Дом",
        flat: "Кв."
      },
			payment: {
				title: 'Способы оплаты',
				сash: 'Наличные',
				card: 'Банковская карта'
			},
			confirmare: {
				title: 'Подтверждение',
				comments: 'Комментарии',
				promo: 'Промокод',
				delivery: 'Доставка',
				total: 'Всего',
				finish: 'Завершить заказ',
				conditions: 'Согласен с условиями',
				policy: 'Политика конфиденциальности'
			},
      useCashback: "Использовать бонусы"
		}
	})
}